<template>
  <div v-loading="loading" class="union-base">
    <div class="union-header">
      <img src="@/assets/uExam/logo.png" alt="" />
      <div class="text">{{ $route.query.name }}-成绩发布</div>
    </div>
    <div class="content">
      <div class="tag">
        <a-icon
          type="exclamation-circle"
          theme="filled"
        />注意：系统默认不发布到学生，若要设置发布到学生的数据范围，请设置报告参数。
      </div>
      <div class="title">发布成绩到学生</div>
      <div class="mb32">
        <a-radio-group
          v-model="form.publishStudent"
          :options="studentOptions"
        />
      </div>
      <template v-if="form.publishStudent === 2">
        <div class="title">选择发布范围</div>
        <a-space style="margin-bottom: 18px">
          <a-input
            v-model="input"
            style="width: 180px"
            allow-clear
            placeholder="请输入学校名称"
          ></a-input>
          <a-button type="primary" ghost @click="searchSubmit">搜索</a-button>
        </a-space>
        <div style="margin-bottom: 18px">
          <a-space>
            <a-button type="link" @click="allCheck">全选</a-button>
            <a-button type="link" @click="reverse">反选</a-button>
          </a-space>
        </div>
        <div>
          <a-checkbox-group v-model="checkList" style="margin-bottom: 18px">
            <a-checkbox
              v-for="(item, index) in schoolList"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </a-checkbox>
          </a-checkbox-group>
        </div>
      </template>
      <template v-if="[1, 2].includes(form.publishStudent)"
        ><div class="title">是否展示答题卡</div>
        <div class="mb32">
          <a-radio-group
            v-model="form.publishStudentIsDisplayAnswerSheet"
            :options="[
              { label: '不显示', value: 0 },
              { label: '显示', value: 1 },
            ]"
          />
        </div>
        <div class="title">是否显示平均分</div>
        <div class="mb32">
          <a-radio-group
            v-model="form.publishStudentIsDisplayAverageScore"
            :options="[
              { label: '不显示', value: 0 },
              { label: '显示联考平均分（含学校、班级平均分）', value: 1 },
              { label: '显示学校平均分（含班级平均分）', value: 2 },
              { label: '显示班级平均分', value: 3 },
            ]"
          />
        </div>
        <div class="title">是否显示排名</div>
        <div class="mb32">
          <a-radio-group
            v-model="form.publishStudentIsDisplayRank"
            :options="[
              { label: '不显示', value: 0 },
              { label: '显示联考排名（含学校、班级排名）', value: 1 },
              { label: '显示学校排名（含班级排名）', value: 2 },
              { label: '显示班级排名', value: 3 },
            ]"
          />
        </div>
        <div class="title">是否显示学生位置</div>
        <div class="mb32">
          <a-radio-group
            v-model="form.publishStudentIsDisplayLevel"
            :options="[
              { label: '不显示', value: 0 },
              { label: '显示联考位置（含学校、班级位置）', value: 1 },
              { label: '显示学校位置（含班级位置）', value: 2 },
              { label: '显示班级位置', value: 3 },
            ]"
          />
        </div>
        <div class="title">是否显示题目得分情况（移动端同步生效）</div>
        <div class="mb32">
          <a-radio-group
            v-model="form.publishStudentIsDisplayQuestion"
            :options="[
              { label: '不显示', value: 0 },
              { label: '显示', value: 1 },
            ]"
          />
        </div>
        <div class="flex">
          是否显示得分率（移动端同步）：
          <a-radio-group
            v-model="form.publishStudentIsDisplayQuestionScoreRate"
            :options="[
              { label: '不显示', value: 0 },
              { label: '显示联考得分率 (含学校、班级得分率)', value: 1 },
              { label: '显示学校得分率(含班级得分率)', value: 2 },
              { label: '显示班级得分率', value: 3 },
            ]"
          />
        </div>
        <div class="flex">
          是否显示最高分：
          <a-radio-group
            v-model="form.publishStudentIsDisplayQuestionHighestScore"
            :options="[
              { label: '不显示', value: 0 },
              { label: '显示联考最高分 (含学校最高分)', value: 1 },
              { label: '显示学校最高分', value: 2 },
            ]"
          />
        </div>
        <div class="flex">
          是否显示满分人数：
          <a-radio-group
            v-model="form.publishStudentIsDisplayQuestionCountRight"
            :options="[
              { label: '不显示', value: 0 },
              { label: '显示联考满分人数（含学校满分人数）', value: 1 },
              { label: '显示学校满分人数', value: 2 },
            ]"
          />
        </div>
        <div class="title">是否显示知识点分析（移动端同步生效）</div>
        <div class="mb32">
          <a-radio-group
            v-model="form.publishStudentIsDisplayKnowledge"
            :options="[
              { label: '不显示', value: 0 },
              { label: '显示', value: 1 },
            ]"
          />
        </div>
        <div class="flex">
          是否显示得分率：
          <a-radio-group
            v-model="form.publishStudentIsDisplayKnowledgeScoreRate"
            :options="[
              { label: '不显示', value: 0 },
              { label: '显示联考得分率（含学校、班级得分率）', value: 1 },
              { label: '显示学校得分率（含班级得分率）', value: 2 },
              { label: '显示班级得分率', value: 3 },
            ]"
          />
        </div>
        <div class="title">是否显示学科雷达图：</div>
        <div class="mb32">
          <a-radio-group
            v-model="form.publishStudentIsDisplaySubjectRadarChart"
            :options="[
              { label: '不显示', value: 0 },
              { label: '显示', value: 1 },
            ]"
          />
        </div>
        <div class="title">是否显示试卷：</div>
        <div class="mb32">
          <a-radio-group
            v-model="form.publishStudentIsDisplayPaper"
            :options="[
              { label: '不显示', value: 0 },
              { label: '显示', value: 1 },
            ]"
          />
        </div>
        <div class="flex">
          是否显示得分率：
          <a-radio-group
            v-model="form.publishStudentIsDisplayPaperScoreRate"
            :options="[
              { label: '不显示', value: 0 },
              { label: '显示联考得分率 (含学校、班级得分率)', value: 1 },
              { label: '显示学校得分率（含班级得分率）', value: 2 },
              { label: '显示班级得分率', value: 3 },
            ]"
          /></div
      ></template>

      <div class="btn">
        <a-button type="primary" :loading="btnLoading" @click="submit">{{
          form.publishStudent === 0 ? "确定" : "发布到学生"
        }}</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getstatschooloption } from "@/core/api/academic/common";
import { publishstudent } from "@/core/api/academic/unionReport";
import { getstatinfo } from "@/core/api/academic/common";
export default {
  name: "",
  data() {
    return {
      form: {},
      studentOptions: [
        { label: "不发布", value: 0 },
        { label: "发布所有学校", value: 1 },
        { label: "发布部分学校", value: 2 },
      ],
      schoolList: [],
      originSchool: [],
      btnLoading: false,
      statData: {},
      loading: false,
      input: "",
      checkList: [],
    };
  },
  computed: {},
  created() {
    this.getSchoolList();
  },
  methods: {
    async getData() {
      this.loading = true;
      const res = await getstatinfo({ id: this.$route.query.id });
      this.form = { ...res.data.data.stat };
      if (res.data.data.stat.publishStudentSchoolIds) {
        this.checkList = res.data.data.stat.publishStudentSchoolIds
          .split(",")
          .map(Number);
      }
      this.loading = false;
    },
    searchSubmit() {
      if (this.input) {
        this.schoolList = this.originSchool.filter(
          (item) => item.name.indexOf(this.input) !== -1
        );
      } else {
        this.schoolList = [...this.originSchool];
      }
    },
    async submit() {
      this.btnLoading = true;
      try {
        await publishstudent({
          statId: this.$route.query.id,
          ...this.form,
          publishStudentSchoolIds: this.checkList.join(","),
        });
        this.$message({
          message: "保存成功",
          showClose: true,
          type: "success",
        });
        this.getData();
        this.btnLoading = false;
      } catch {
        this.btnLoading = false;
      }
    },
    allCheck() {
      this.checkList = this.schoolList.map((item) => item.id);
    },
    reverse() {
      this.checkList = this.schoolList.filter(
        (item) => !this.checkList.includes(item.id)
      );
    },
    async getSchoolList() {
      const res = await getstatschooloption({ id: this.$route.query.id });
      this.schoolList = res.data.data;
      this.originSchool = res.data.data;
      this.getData();
    },
  },
};
</script>
<style lang="scss" scoped>
.union-base {
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  .union-header {
    height: 44px;
    background: #2e3137;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 0 24px;
    color: #ffffff;
    img {
      margin-right: 24px;
    }
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .content {
    width: 1200px;
    background: #ffffff;
    box-sizing: border-box;
    padding: 18px;
    margin: 0 auto;
    margin-top: 18px;
    .tag {
      height: 40px;
      background: #fff3f3;
      border-radius: 4px;
      line-height: 40px;
      color: #292626;
      font-size: 14px;
      margin-bottom: 18px;
      i {
        padding: 0 10px;
        color: #f56c6b;
      }
    }
    .title {
      background: #f9fafc;
      border-radius: 4px;
      height: 36px;
      line-height: 36px;
      box-sizing: border-box;
      padding: 0 8px;
      color: #0a1119;
      margin-bottom: 26px;
      font-weight: 600;
    }
    .mb32 {
      margin-bottom: 32px;
    }
    .btn {
      text-align: center;
    }
  }
}
.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 32px;
}
::v-deep .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
::v-deep .ant-checkbox-wrapper {
  margin-right: 8px;
  margin-bottom: 18px;
}
</style>
